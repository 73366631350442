var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!!_vm.obj)?_c('div',[_c('div',{staticClass:"ann-card",class:_vm.obj.icon ? _vm.obj.icon : _vm.determineClass},[_c('header',[_c('div',{staticClass:"d-flex"},[(_vm.obj.cat && (_vm.obj.cat == 'camp' || _vm.obj.icon == 'camp'))?_c('div',{staticClass:"icon"},[_c('iCamp')],1):_vm._e(),(_vm.obj.cat && (_vm.obj.cat == 'state' || _vm.obj.icon == 'state'))?_c('div',{staticClass:"icon"},[_c('iState')],1):_vm._e(),(_vm.obj.cat && (_vm.obj.cat == 'international' || _vm.obj.icon == 'international'))?_c('div',{staticClass:"icon"},[_c('iInternational')],1):_vm._e(),(
            _vm.obj.cat &&
            _vm.obj.cat != 'camp' &&
            _vm.obj.icon != 'camp' &&
            _vm.obj.cat != 'state' &&
            _vm.obj.icon != 'state' &&
            _vm.obj.cat != 'international' &&
            _vm.obj.icon != 'international'
          )?_c('div',{staticClass:"icon"},[_c('iOther')],1):_vm._e(),_c('div',{staticClass:"meta"},[_vm._t("header"),(_vm.obj.state || _vm.obj.cat || _vm.obj.testimony_type !== "Other")?_c('h5',{staticClass:"cat"},[_vm._v(" "+_vm._s(_vm.setLabel)+" ")]):_vm._e(),_c('span',{staticClass:"ann-date"},[_vm._v(_vm._s(_vm.objDate))]),(!!_vm.obj.attachments && _vm.obj.attachments.length > 0)?_c('span',{staticClass:"pap-attach"},[_c('iAttachment')],1):_vm._e()],2)])]),_c('main',[_c('div',{staticClass:"entry"},[_c('h3',{staticClass:"title",attrs:{"title":_vm.obj.msg_title}},[_vm._v(_vm._s(_vm.obj.ttl || _vm.obj.title))]),_c('div',{staticClass:"entry-content",class:[_vm.obj.ttl ? 'shorter' : '']},[(_vm.obj.msg_message)?_c('div',{staticClass:"content-text",domProps:{"innerHTML":_vm._s(_vm.previewRendered)}}):_vm._e(),(_vm.obj.desc)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.obj.desc)}}):_vm._e()])]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.handleViewMoreClick(_vm.obj)}}},[_vm._v(_vm._s(_vm.i18n['ann-card'].tcReadMore))]),(_vm.obj.art_key)?_c('div',{staticClass:"entry tags"},[_c('b-row',[_c('b-col',{staticClass:"font-style-3 tags",attrs:{"sm":"4"}},[_c('h3',{staticClass:"title text-uppercase"},[_vm._v(_vm._s(_vm.i18n['ann-card'].tcTags)+":")])]),_c('b-col',{staticClass:"font-style-3",attrs:{"sm":"8"}},[_c('div',{staticClass:"entry-content shorter"},[(_vm.obj.city || _vm.obj.state || _vm.obj.other || _vm.obj.topic)?_c('div',[_vm._v(" "+_vm._s(_vm.obj.city || '')+" "+_vm._s(_vm.obj.state || '')+" "+_vm._s(_vm.obj.other || '')+" "+_vm._s(_vm.obj.topic || '')+" ")]):_vm._e()])])],1)],1):_vm._e()],1),_c('footer',[(_vm.obj.country)?_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.obj.country))]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }